import React from 'react'

import Down from '../elements/Down'

import feat01 from '../../assets/images/feat01.png'
import feat02 from '../../assets/images/feat02.png'
import feat03 from '../../assets/images/feat03.png'

const Features = () => {
  return (
    <section className='section features'>
      <div className="container text-center mt-5 pt-4">
        <div className="row">
          <div className="col-md-4 p-4">
            <Down delay={0}><img src={feat01} alt='Асесмент+' className='r-circle obfc' /></Down>
            <div className="s-20"></div>
            <Down delay={0.25}><h4 className="title-small c-dark">Асесмент+</h4></Down>
            <div className="s-20"></div>
            <Down delay={.5}><p className="title-mini px-3">Унікальна система оцінки кандидатів за допомогою інтелектуального аналізу та оцінки навичок та якостей. Ми використовуємо штучний інтелект для швидкої та ефективної оцінки кандидатів, що дозволяє вам знайти найбільш підходящих кандидатів за мінімальний час та зусилля.</p></Down>
          </div>
          <div className="col-md-4 p-4">
            <Down delay={.5}><img src={feat02} alt='Фідбек+' className='r-circle obfc' /></Down>
            <div className="s-20"></div>
            <Down delay={.75}><h4 className="title-small c-dark">Фідбек+</h4></Down>
            <div className="s-20"></div>
            <Down delay={1}><p className="title-mini px-3">Автоматизований процес збору та надання зворотного зв'язку для ефективного та швидкого відгуку на вакансії. Наш сервіс дозволяє вам надавати зворотний зв'язок кандидатам з максимальною точністю та ефективністю, що допомагає підтримувати добрі стосунки з потенційними працівниками.</p></Down>
          </div>
          <div className="col-md-4 p-4">
            <Down delay={1}><img src={feat03} alt='Рекрут+' className='r-circle obfc' /></Down>
            <div className="s-20"></div>
            <Down delay={1.25}><h4 className="title-small c-dark">Рекрут+</h4></Down>
            <div className="s-20"></div>
            <Down delay={1.5}><p className="title-mini px-3">Інтеграція з різними сайтами та платформами для швидкого пошуку та відбору потенційних кандидатів. Ми забезпечуємо максимальний охоплення різних джерел рекрутингу, що дозволяє вам знайти найбільш талановитих кандидатів за мінімальний час та зусилля.</p></Down>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
