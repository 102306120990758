import React from 'react'
import Right from '../elements/Right'
import Top from '../elements/Top'
import Down from '../elements/Down'

const Hero = () => {
  return (
    <section className='section hero'>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="s-80"></div>
            <Top><h1 className="title-big c-dark">Таланти?</h1></Top>
            <Down delay={.5}><h1 className="title-big c-white">Легко!</h1></Down>
            <div className="s-60"></div>
            <Right delay={1}><p className="title-small c-white">Успішний найм IT-спеціалістів стає можливим завдяки нашому сервісу з штучним інтелектом, що забезпечує брендування, економію часу та юридичний захист. Таланти? Легко!</p></Right>
          </div>
        </div>
        <div className="s-60"></div>
        <div className="row">
          <div className="col-md-3">
            <Right delay={1}>
              <div className="bg-white h-10 flex jcc aic">
                <p className="title-medium m0p0 c-dark">Асесмент +</p>
              </div>
            </Right>
          </div>
          <div className="col-md-3">
            <Right delay={.75}>
              <div className="bg-white h-10 flex jcc aic">
                <p className="title-medium m0p0 c-dark">Фідбек +</p>
              </div>
            </Right>
          </div>
          <div className="col-md-3">
            <Right delay={.5}>
              <div className="bg-white h-10 flex jcc aic">
                <p className="title-medium m0p0 c-dark">Резюме +</p>
              </div>
            </Right>
          </div>
          <div className="col-md-3">
            <Right delay={.25}>
              <div className="bg-white h-10 flex jcc aic">
                <p className="title-medium m0p0 c-dark">Моніторинг +</p>
              </div>
            </Right>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
