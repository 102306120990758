import React from 'react'

import Hero from '../parts/Hero'
import Choise from '../parts/Choise'
import Cta from '../parts/Cta'
import Features from '../parts/Features'
import Help from '../parts/Help'
import How from '../parts/How'
import Agenda from '../parts/Agenda'

const Home = () => {
  return (
    <>
      <Hero />
      <Choise />
      <Cta />
      <Features />
      <Help />
      <How />
      <Agenda />
    </>
  )
}

export default Home
