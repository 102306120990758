import React from 'react'
import {Link} from 'react-router-dom'

import Down from '../elements/Down'
import Right from '../elements/Right'
import Left from '../elements/Left'
import Top from '../elements/Top'

import pic from '../../assets/images/help.png'
import help01 from '../../assets/images/help01.png'
import help02 from '../../assets/images/help02.png'
import help03 from '../../assets/images/help03.png'

const Help = () => {
  return (
    <section className='section help'>
      <div className="container-fluid m0p0">
        <div className="row">
          <div className="col-md-6">
            <div className="flex w-100 h-100 jcc aic px-5">
              <div>
                <Top delay={.5}><h3 className="title-large c-dark">Допоможемо знайти вам роботу</h3></Top>
                <div className="s-60"></div>
                <Right delay={.75}><p className="title-small">Ми знаємо, що ви шукаєте роботу, яка відповідає вашим навичкам та потребам, і ми допоможемо вам знайти роботу за допомогою нашого сервісу.</p></Right>
                <div className="s-60"></div>
                <Right delay={1}><Link to='#' className="button">Поговоримо?</Link></Right>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="s-20"></div>
            <Left><img src={pic} alt='Швидкий та ефективний найм IT-спеціалістів' className='h-100 obfc' /></Left>
            <div className="s-20"></div>
          </div>
        </div>
      </div>
      <div className="container text-center mb-5 py-4">
        <div className="row">
          <div className="col-md-4 p-4">
            <Down delay={1.5}>
              <div className="bg-white w-100 h-60 py-4">
                <Down delay={0}><img src={help01} alt='Асесмент+' className='r-circle obfc' /></Down>
                <div className="s-20"></div>
                <Down delay={.25}><h4 className="title-small c-dark">Резюме+</h4></Down>
                <div className="s-20"></div>
                <Down delay={0.5}><p className="title-mini px-3">Генерація професійного резюме для враження рекрутерів. Ми допоможемо вам створити професійне резюме, яке відповідає потребам вашої ідеальної роботи.</p></Down>
              </div>
            </Down>
          </div>
          <div className="col-md-4 p-4">
            <Down delay={1.75}>
              <div className="bg-white w-100 h-60 py-4">
                <Down delay={0.5}><img src={help02} alt='Фідбек+' className='r-circle obfc' /></Down>
                <div className="s-20"></div>
                <Down delay={0.75}><h4 className="title-small c-dark">Моніторинг+</h4></Down>
                <div className="s-20"></div>
                <Down delay={1}><p className="title-mini px-3">Слідкуймо за рекрутерами, які вас цікавлять. Наш сервіс дозволяє вам відстежувати активність рекрутерів, щоб ви могли бути в курсі статусу своєї заявки та отримувати своєчасний фідбек.</p></Down>
              </div>
            </Down>
          </div>
          <div className="col-md-4 p-4">
            <Down delay={2}>
              <div className="bg-white w-100 h-60 py-4">
                <Down delay={1}><img src={help03} alt='Рекрут+' className='r-circle obfc' /></Down>
                <div className="s-20"></div>
                <Down delay={1.25}><h4 className="title-small c-dark">Підготовка+</h4></Down>
                <div className="s-20"></div>
                <Down delay={1.5}><p className="title-mini px-3">Підготуйтеся до інтерв'ю з нашою допомогою. Ми допоможемо вам підготуватися до інтерв'ю, щоб ви могли вражати рекрутерів своїми навичками та професійністю.</p></Down>
              </div>
            </Down>
          </div>
          <div className="s-40"></div>
        </div>
      </div>
    </section>
  )
}

export default Help
