import React from 'react'
import Title from '../elements/Title'

const Privacy = () => {
  return (
    <>
      <Title text='Політика конфіденційності' desc='останнє оновлення 15 травня 2023' />
      <section className="my-5 py-md-5 container p-5 m-5 page-content">
        <div className="s-40"></div>
        <p>Це повідомлення про конфіденційність для Pogovorimo (<a href="https://www.pogovorimo.com.ua">https://www.pogovorimo.com.ua</a>) («Компанія», «ми», «нас» або «наш») описує, як і чому ми можемо збирати , зберігати, використовувати та/або надавати ("обробляти") вашу інформацію, коли ви користуєтеся нашими службами ("Сервіси"), наприклад, коли ви:</p>
        <ul>
          <li>відвідуєте наш веб-сайт за адресою <a href="https://www.pogovorimo.com.ua">https://www.pogovorimo.com.ua</a> або будь-який наш веб-сайт, який посилається на це повідомлення про конфіденційність</li>
          <li>Взаємодіяти з нами іншими пов’язаними способами, включно з будь-якими продажами, маркетингом або подіями</li>
        </ul>
        <p>Маєте запитання чи сумніви? Ознайомлення з цим повідомленням про конфіденційність допоможе вам зрозуміти ваші права та вибір щодо конфіденційності. Якщо ви не згодні з нашою політикою, будь ласка, не користуйтеся нашими Послугами. Якщо у вас все ще є запитання чи сумніви, зв’яжіться з нами за  адресою <a href="mailto:admin@pogovorimo.com.ua">admin@pogovorimo.com.ua</a>.</p>
        <p>РЕЗЮМЕ КЛЮЧОВИХ ПУНКТІВ</p>
        <p>У цьому короткому викладі наведено ключові моменти з нашої політики конфіденційності.</p>
        <p>Яку особисту інформацію ми обробляємо? Коли ви відвідуєте, використовуєте або переміщуєтеся в наших Послугах, ми можемо обробляти особисту інформацію залежно від того, як ви взаємодієте з нашими Послугами, вибору, який ви робите, а також продуктів і функцій, які ви використовуєте.</p>
        <p>Чи обробляємо ми конфіденційну особисту інформацію? Ми не обробляємо конфіденційну особисту інформацію.</p>
        <p>Чи отримуємо ми будь-яку інформацію від третіх сторін? Ми не отримуємо жодної інформації від третіх осіб.</p>
        <p>Як ми обробляємо вашу інформацію? Ми обробляємо вашу інформацію, щоб надавати, покращувати та адмініструвати наші Послуги, спілкуватися з вами, для безпеки та запобігання шахрайству, а також для дотримання законодавства. Ми також можемо обробляти вашу інформацію для інших цілей за вашою згодою. Ми обробляємо вашу інформацію лише тоді, коли у нас є вагома законна підстава для цього.</p>
        <p>У яких ситуаціях і яким сторонам ми надаємо особисту інформацію? Ми можемо ділитися інформацією в певних ситуаціях і з певними категоріями третіх сторін.</p>
        <p>Як ми забезпечуємо безпеку вашої інформації? У нас є організаційні та технічні процеси та процедури для захисту вашої особистої інформації. Однак жодна електронна передача через Інтернет або технологія зберігання інформації не може бути гарантовано на 100% безпечною, тому ми не можемо обіцяти або гарантувати, що хакери, кіберзлочинці чи інші неавторизовані треті сторони не зможуть порушити нашу безпеку та неналежним чином збирати, отримувати доступ, викрасти чи змінити вашу інформацію.</p>
        <p>Які ваші права? Залежно від того, де ви географічно розташовані, відповідний закон про конфіденційність може означати, що ви маєте певні права щодо своєї особистої інформації.</p>
        <p>Як ви реалізуєте свої права? Найпростіший спосіб скористатися своїми правами – зв’язатися з нами електронною поштою. Ми розглянемо будь-який запит і відповімо на нього відповідно до чинного законодавства про захист даних.</p>
        <p>Хочете дізнатися більше про те, що ми робимо з інформацією, яку збираємо? Перегляньте інформацію нижче.</p>
        <p>ЗМІСТ</p>
        <ul>
          <li>1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</li>
          <li>2. ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</li>
          <li>3. ЯКИМИ ПРАВОВИМИ ПІДСТАВАМИ МИ КЕРУЄМОСЯ ДЛЯ ОБРОБКИ ВАШОЇ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ?</li>
          <li>4. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?</li>
          <li>5. ЧИ ВИКОРИСТОВУЄМО МИ ФАЙЛИ COOKIE ТА ІНШІ ТЕХНОЛОГІЇ ВІДСТЕЖЕННЯ?</li>
          <li>6. ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</li>
          <li>7. ЯК МИ ЗАБЕЗПЕЧУЄМО БЕЗПЕКУ ВАШОЇ ІНФОРМАЦІЇ?</li>
          <li>8. ЧИ МИ ЗБИРАЄМО ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?</li>
          <li>9. ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</li>
          <li>10. ЕЛЕМЕНТИ КЕРУВАННЯ ФУНКЦІЯМИ НЕ ВІДСТЕЖУВАТИ</li>
          <li>11. ЧИ МАЮТЬ ЖИТЕЛІ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</li>
          <li>12. ЧИ МИ РОБИМО ОНОВЛЕННЯ ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</li>
          <li>13. ЯК ВИ МОЖЕТЕ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</li>
          <li>14. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЯКІ МИ ЗБИРАЄМО ВІД ВАС?</li>
        </ul>
        <p>1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</p>
        <p>Особиста інформація, яку ви нам розкриваєте</p>
        <p>Коротко: ми збираємо особисту інформацію, яку ви нам надаєте.</p>
        <p>Ми збираємо особисту інформацію, яку ви добровільно надаєте нам, коли реєструєтеся на нашому сайті, виражаєте зацікавленість в отриманні інформації про нас або наші продукти та Послуги, коли ви заповнюєте форму на  нашому  сайті або іншим чином, коли звертаєтеся до нас.</p>
        <p>Особиста інформація, надана вами. Особиста інформація, яку ми збираємо, залежить від контексту вашої взаємодії з нами та Сервісами, вибору, який ви робите, а також продуктів і функцій, які ви використовуєте. Особиста інформація, яку ми збираємо, може включати наступне:</p>
        <ul>
          <li>ПІБ,</li>
          <li>номери телефонів</li>
          <li>адреса електронної пошти,</li>
          <li>поштова адреса</li>
          <li>РНОКПП</li>
          <li>посилання на сторінку Linkedin</li>
          <li>фотографія з вашим зображенням</li>
          <li>відео з вашим зображенням</li>
          <li>CV</li>
        </ul>
        <p>Конфіденційна інформація. Ми не обробляємо конфіденційну інформацію.</p>
        <p>Уся особиста інформація, яку ви надаєте нам, має бути правдивою, повною та точною, і ви повинні повідомляти нас про будь-які зміни такої особистої інформації.</p>
        <p>Інформація, що збирається автоматично</p>
        <p>Коротко: деяка інформація, наприклад ваша адреса Інтернет-протоколу (IP) і/або характеристики браузера та пристрою, збирається автоматично, коли ви відвідуєте наші Сервіси.</p>
        <p>Ми автоматично збираємо певну інформацію, коли ви відвідуєте, використовуєте або переміщуєтеся Сервісами. Ця інформація не розкриває вашу особистість (наприклад, ваше ім’я чи контактну інформацію), але може включати інформацію про пристрій і використання, як-от вашу IP-адресу, характеристики веб-переглядача та пристрою, операційну систему, мовні налаштування, URL-адреси переходу, назву пристрою, країну, місцезнаходження, інформацію про те, як і коли ви використовуєте наші Послуги, а також іншу технічну інформацію. Ця інформація в першу чергу потрібна для забезпечення безпеки та роботи наших Сервісів, а також для внутрішньої аналітики та звітності.</p>
        <p>Як і багато компаній, ми також збираємо інформацію за допомогою файлів cookie та подібних технологій.</p>
        <p>Інформація, яку ми збираємо, включає:</p>
        <p>дані журналу та використання. Дані журналу та використання — це інформація, пов’язана з послугами, діагностикою, використанням і продуктивністю, яку наші сервери автоматично збирають, коли ви отримуєте доступ до наших Послуг або використовуєте їх, і яку ми записуємо у файли журналів. Залежно від того, як ви взаємодієте з нами, дані журналу можуть включати вашу IP-адресу, інформацію про пристрій, тип браузера, а також, наприклад, позначки дати/часу, пов’язані з вашим використанням, сторінки та файли, які ви переглядаєте, пошуки та інші дії, які ви виконуєте, наприклад, які функції ви використовуєте, інформацію про події пристрою (такі як системна активність, звіти про помилки (іноді їх називають «аварійними дампами») та налаштування апаратного забезпечення).</p>
        <p>2. ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</p>
        <p>
Коротко: ми обробляємо вашу інформацію, щоб надавати, покращувати та адмініструвати наші Сервіси, спілкуватися з вами, для безпеки та запобігання шахрайству, а також для дотримання законодавства. Ми також можемо обробляти вашу інформацію для інших цілей за вашою згодою.</p>
        <p>Ми обробляємо вашу особисту інформацію з різних причин, залежно від того, як ви взаємодієте з нашими Сервісами, зокрема:</p>
        <p>Для укладення угод с вами. Ми можемо обробляти вашу інформацію для формування відповідних угод із вами та надіслання їх на вашу електронну адресу для підписання.</p>
        <p>Для полегшення створення облікового запису та автентифікації та іншого керування обліковими записами користувачів. Ми можемо обробляти вашу інформацію, щоб ви могли створити обліковий запис і ввійти в нього, а також підтримувати його в робочому стані.</p>
        <p>Відповідати на запити користувачів/пропонувати підтримку користувачам. Ми можемо обробляти вашу інформацію, щоб відповісти на ваші запити та вирішити будь-які потенційні проблеми, які можуть виникнути у вас із запитаною послугою.</p>
        <p>Щоб надіслати вам адміністративну інформацію. Ми можемо обробляти вашу інформацію, щоб надсилати вам докладну інформацію про наші продукти та послуги, зміни наших умов і політики та іншу подібну інформацію.</p>
        <p>Щоб запитати відгук. Ми можемо обробляти вашу інформацію, коли це необхідно, щоб отримати відгук і зв’язатися з вами щодо використання вами наших Послуг.</p>
        <p>Щоб надсилати вам маркетингові та рекламні повідомлення. Ми можемо обробляти особисту інформацію, яку ви надсилаєте нам, для наших маркетингових цілей, якщо це відповідає вашим маркетинговим уподобанням. Ви можете будь-коли відмовитися від наших маркетингових електронних листів. Для отримання додаткової інформації див. «ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?» нижче).</p>
        <p>Для доставки вам цільової реклами. Ми можемо обробляти вашу інформацію для розробки та відображення персоналізованого вмісту та реклами відповідно до ваших інтересів, місцезнаходження тощо.</p>
        <p>Щоб захистити наші Сервіси. Ми можемо обробляти вашу інформацію в рамках наших зусиль, щоб забезпечити безпеку та безпеку наших Сервісів, включаючи моніторинг та запобігання шахрайству.</p>
        <p>Щоб визначити тенденції використання. Ми можемо обробляти інформацію про те, як ви використовуєте наші Сервіси, щоб краще зрозуміти, як вони використовуються, щоб ми могли їх покращити.</p>
        <p>Щоб визначити ефективність наших маркетингових і рекламних кампаній. Ми можемо обробляти вашу інформацію, щоб краще зрозуміти, як проводити маркетингові та рекламні кампанії, які є найбільш релевантними для вас.</p>
        <p>Для збереження або захисту життєво важливих інтересів особи. Ми можемо обробляти вашу інформацію, коли це необхідно для збереження або захисту життєво важливих інтересів особи, наприклад, щоб запобігти завданню шкоди.</p>
        <p>Для покращення та популяризації наших Сервісів. Ми можемо обробляти вашу інформацію шляхом розміщення фотографій та відео із вашим зображенням на наших веб-сайтах (сервісах), та веб- сайтах наших партнерів. При цьому ми можемо покращувати якість наданих вами фото та відео у будь-якій спосіб без спотворення вашого зображення (це може бути кроп, заміна фону, яркості, тощо).</p>
        <p>3. ЯКИМИ ПРАВОВИМИ ПІДСТАВАМИ МИ КЕРУЄМОСЯ ДЛЯ ОБРОБКИ ВАШОЇ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ?</p>
        <p>Коротко: ми обробляємо вашу особисту інформацію лише тоді, коли вважаємо це необхідним і маємо вагомі правові підстави (тобто правові підстави) для цього відповідно до чинного законодавства, наприклад, за вашою згодою, для дотримання законів, для надання вам послуг для прийняття або виконання наших договірних зобов’язань, захисту ваших прав або виконання наших законних ділових інтересів.</p>
        <p>Якщо ви перебуваєте в ЄС або Великобританії, цей розділ стосується вас.</p>
        <p>Загальний регламент захисту даних (GDPR) і GDPR Великобританії вимагають від нас пояснення чинних правових підстав, на які ми спираємося для обробки вашої особистої інформації. Таким чином, ми можемо покладатися на такі правові основи для обробки вашої особистої інформації:</p>
        <p>Згода. Ми можемо обробляти вашу інформацію, якщо ви дали нам дозвіл (тобто згоду) на використання вашої особистої інформації з певною метою. Ви можете відкликати свою згоду в будь-який час.</p>
        <p>Виконання контракту. Ми можемо обробляти вашу особисту інформацію, якщо вважаємо це необхідним для виконання наших договірних зобов’язань перед вами, включаючи надання наших Послуг, або за вашим запитом до укладення з вами контракту.</p>
        <p>Законні інтереси. Ми можемо обробляти вашу інформацію, якщо вважаємо, що це обґрунтовано необхідно для досягнення наших законних ділових інтересів, і ці інтереси не переважають ваші інтереси та основні права та свободи. Наприклад, ми можемо обробляти вашу особисту інформацію для деяких із описаних цілей, щоб:</p>
        <ul>
          <li>надсилати користувачам інформацію про спеціальні пропозиції та знижки на наші продукти та послуги;</li>
          <li>розробляти та відображати персоналізований і відповідний рекламний вміст для наших користувачів (в тому  числі шляхом розміщення фотографій та відео із вашим зображенням на наших веб-сайтах (сервісах), та веб-сайтах наших партнерів);</li>
          <li>аналізувати, як використовуються наші Сервіси, щоб ми могли покращити їх, щоб залучати й утримувати користувачів;</li>
          <li>Підтримувати нашу маркетингову діяльність;</li>
          <li>Діагностувати проблеми та/або запобігати шахрайським діям;</li>
        </ul>
        <p>Зрозуміти, як наші користувачі використовують наші продукти та послуги, щоб ми могли покращити взаємодію з ними.</p>
        <p>Юридичні зобов’язання. Ми можемо обробляти вашу інформацію, якщо вважаємо, що це необхідно для дотримання наших юридичних зобов’язань, наприклад, для співпраці з правоохоронними органами або регулюючими (контролюючими) органами, реалізації або захисту наших законних прав або розкриття вашої інформації як доказу в судовому процесі, в який ми залучені.</p>
        <p>Життєво важливі інтереси. Ми можемо обробляти вашу інформацію, якщо вважаємо це необхідним для захисту ваших життєво важливих інтересів або життєво важливих інтересів третьої сторони, наприклад, у ситуаціях, пов’язаних із потенційною загрозою безпеці будь-якої особи.</p>
        <p>З юридичної точки зору ми зазвичай є «контролером даних» відповідно до європейських законів про захист персональних даних, описаних у цієї політики  конфіденційності, оскільки ми визначаємо засоби та/або цілі обробки даних, які ми виконуємо.  Ця політика  конфіденційності не стосується особистої інформації, яку ми обробляємо як «обробник даних» від імені наших клієнтів. У таких ситуаціях клієнт, якому ми надаємо послуги та з яким ми уклали угоду про обробку даних, є «контролером даних», відповідальним за вашу особисту інформацію, і ми лише обробляємо вашу інформацію від його імені відповідно до ваших інструкцій. Якщо ви хочете дізнатися більше про методи забезпечення конфіденційності наших клієнтів, ви повинні прочитати їхню політику конфіденційності та спрямувати їм будь-які запитання.</p>
        <p>
Якщо ви перебуваєте в Канаді, цей розділ стосується вас.</p>
        <p>Ми можемо обробляти вашу інформацію, якщо ви дали нам окремий дозвіл (тобто явну згоду) на використання вашої особистої інформації для певної мети або в ситуаціях, коли ваш дозвіл можна припустити (тобто непряма згода). Ви можете відкликати свою згоду в будь-який час.</p>
        <p>У деяких виняткових випадках відповідно до чинного законодавства нам може бути дозволено обробляти вашу інформацію без вашої згоди, зокрема, наприклад:</p>
        <ul>
          <li>Якщо збір явно відповідає інтересам особи, а згоду не можна отримати своєчасно</li>
          <li>Для розслідувань виявлення і запобігання шахрайства</li>
          <li>Для ділових операцій за умови дотримання певних умов</li>
          <li>Якщо це міститься в показаннях свідка, а збір необхідний для оцінки, обробки або врегулювання страхової претензії</li>
          <li>Для ідентифікації поранених, хворих або померлих осіб і спілкування з найближчими родичами</li>
          <li>Якщо ми маємо обґрунтовані підстави вважати, що особа була, є чи може стати жертвою фінансових зловживань.</li>
          <li>Якщо розумно очікувати, що збір і використання за згодою поставить під загрозу доступність або точність інформації, а збір є розумним для цілей, пов’язаних із розслідуванням порушення угоди або порушення законів Канади чи провінції.</li>
          <li>Якщо розголошення необхідно для виконання за: повісткою в суд, ордером, ухвалою суду або правилами суду, що стосуються надання записів.</li>
          <li>якщо вони були створені особою під час її роботи, бізнесу чи професійної діяльності, і збір відповідає цілям, для яких інформація була створена</li>
          <li>Якщо збір призначений виключно для журналістських, мистецьких або літературних цілей</li>
          <li>Якщо інформація є загальнодоступною та визначена правилами</li>
        </ul>
        <p>4. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?</p>
        <p>Коротко: ми можемо ділитися інформацією в конкретних ситуаціях, описаних у цьому розділі, та/або з такими категоріями третіх сторін.</p>
        <p>Постачальники, консультанти, партнери та інші сторонні постачальники послуг. Ми можемо надавати ваші дані стороннім постачальникам, постачальникам послуг, партнерам, підрядникам або агентам («треті сторони»), які надають послуги для нас (з якими ми маємо партнерські відносини) або від нашого імені та потребують доступу до такої інформації для виконання такої роботи. У нас є контракти (або будуть укладені в момент передачі даних) з нашими третіми сторонами, які призначені для захисту вашої особистої інформації. Це означає, що вони не можуть нічого робити з вашою особистою інформацією, якщо ми не доручимо їм це зробити. Вони також не повідомлятимуть вашу особисту інформацію жодній організації, крім нас. Вони також зобов’язуються захищати дані, які вони зберігають від нашого імені, і зберігати їх протягом періоду, який ми даємо. Категорії третіх осіб, яким ми можемо надавати особисту інформацію: інструменти маркетингу та продажів.</p>
        <p>Нам також може знадобитися надати вашу особисту інформацію в таких ситуаціях: Ми можемо надавати або передавати вашу інформацію у зв’язку з або під час переговорів щодо будь-якого злиття, продажу активів компанії, фінансування або придбання всього або частини нашого бізнесу іншій компанії.</p>
        <p>5. ЧИ ВИКОРИСТОВУЄМО МИ ФАЙЛИ COOKIE ТА ІНШІ ТЕХНОЛОГІЇ ВІДСТЕЖЕННЯ?</p>
        <p>Коротко: ми можемо використовувати файли cookie та інші технології відстеження для збору та зберігання вашої інформації.</p>
        <p>Ми можемо використовувати файли cookie та подібні технології відстеження (наприклад, веб-маяки та пікселі) для доступу або зберігання інформації.</p>
        <p>6. ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</p>
        <p>Коротко: ми зберігаємо вашу інформацію стільки, скільки необхідно для досягнення цілей, викладених у цієї політики конфіденційності, якщо інше не вимагається законом.</p>
        <p>Ми зберігатимемо вашу особисту інформацію лише до тих пір, поки це необхідно для цілей, викладених у цієї політики конфіденційності, за винятком випадків, коли довший період зберігання вимагається або дозволяється законодавством (наприклад, вимогами податкового, бухгалтерського обліку чи іншими правовими вимогами). Жодна мета цього повідомлення не вимагає від нас зберігати вашу особисту інформацію довше тридцяти шести (36) місяців після припинення дії облікового запису користувача або розірвання відповідного договору або відзиву цієї згоди.</p>
        <p>Якщо у нас немає поточної законної комерційної потреби обробляти вашу особисту інформацію, ми або видалимо, або зробимо таку інформацію анонімною, або, якщо це неможливо (наприклад, оскільки ваша особиста інформація зберігалася в резервних архівах), ми безпечно будемо зберігати вашу особисту інформацію та ізолюємо її від будь-якої подальшої обробки, доки не стане можливим видалення.</p>
        <p>7. ЯК МИ ЗАБЕЗПЕЧУЄМО БЕЗПЕКУ ВАШОЇ ІНФОРМАЦІЇ?</p>
        <p>Коротко: ми прагнемо захистити вашу особисту інформацію за допомогою системи організаційних і технічних заходів безпеки.</p>
        <p>Ми запровадили відповідні та розумні технічні та організаційні заходи безпеки, спрямовані на захист будь-якої особистої інформації, яку ми обробляємо. Однак, незважаючи на наші заходи безпеки та зусилля щодо захисту вашої інформації, жодна електронна передача через Інтернет або технологія зберігання інформації не може бути гарантовано на 100% безпечною, тому ми не можемо обіцяти або гарантувати, що хакери, кіберзлочинці чи інші неавторизовані треті сторони не можуть порушити нашу безпеку та неналежним чином збирати, отримувати доступ, викрадати або змінювати вашу інформацію. Незважаючи на те, що ми зробимо все можливе, щоб захистити вашу особисту інформацію, передача особистої інформації до наших Сервісів (а  також із них) здійснюється на ваш власний ризик. Ви повинні отримати доступ до Сервісів лише в безпечному середовищі.</p>
        <p>8. ЧИ МИ ЗБИРАЄМО ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?</p>
        <p>Коротко: ми свідомо не збираємо дані від дітей віком до 18 років і не продаємо їм дані.</p>
        <p>Ми свідомо не збираємо та не продаємо дані дітям віком до 18 років. Користуючись Сервісами, ви підтверджуєте, що вам виповнилося принаймні 18 років, або що ви є батьком або опікуном такої неповнолітньої особи та погоджуєтеся на використання Сервісів неповнолітньою особою. Якщо ми дізнаємося, що була зібрана особиста інформація користувачів віком до 18 років, ми деактивуємо обліковий запис і вживемо розумних заходів для негайного видалення таких даних із наших записів. Якщо вам стало відомо про будь-які дані, які ми могли зібрати від дітей віком до 18 років, зв’яжіться з нами за адресою <a href="mailto:admin@pogovorimo.com.ua">admin@pogovorimo.com.ua</a>.</p>
        <p>9. ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</p>
        <p>Коротко: у деяких регіонах, наприклад у Європейській економічній зоні (ЄЕЗ), Сполученому Королівстві (Великому Королівстві) і Канаді, ви маєте права, які надають вам більший доступ до вашої особистої інформації та контроль над нею. Ви можете переглянути, змінити або припинити дію свого облікового запису в будь-який час.</p>
        <p>У деяких регіонах (наприклад,  Велика Британія Канада) ви маєте певні права відповідно до чинного законодавства про захист даних. Це може включати право (i) вимагати доступу та отримання копії вашої особистої інформації, (ii) вимагати виправлення або видалення; (iii) забороняти обробку вашої особистої інформації, тощо.  За певних обставин ви також можете мати право заперечити проти обробки вашої особистої інформації. Ви можете зробити такий запит, зв’язавшись з нами, використовуючи контактну інформацію, наведену в розділі «ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?» нижче.</p>
        <p>Ми розглянемо будь-який запит і відповімо на нього відповідно до чинного законодавства про захист даних.</p>
        <p>Відкликання вашої згоди: якщо ми покладаємося на вашу згоду на обробку вашої особистої інформації, яка може бути прямою та/або непрямою згодою залежно від чинного законодавства, ви маєте право відкликати свою згоду в будь-який час. Ви можете відкликати свою згоду в будь-який час, зв’язавшись з нами, використовуючи контактну інформацію, наведену в розділі «ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?» нижче.</p>
        <p>Однак зауважте, що це не вплине на законність обробки до її відкликання, а також, якщо це дозволяє чинне законодавство, це не вплине на обробку вашої особистої інформації, яка виконується на підставі законної обробки, крім згоди</p>
        <p>Відмова від маркетингових і рекламних повідомлень: Ви можете будь-коли скасувати підписку на наші маркетингові та рекламні повідомлення, відповівши «СТОП» або «СКАСУВАТИ ПІДПИСКУ» на SMS-повідомлення, які ми надсилаємо, або зв’язавшись з нами, використовуючи інформацію, наведену в розділі «ЯК ЧИ МОЖЕТЕ ВИ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?" нижче. Після цього вас буде видалено з маркетингових списків. Однак ми все одно можемо спілкуватися з вами, наприклад, щоб надсилати вам пов’язані з послугами повідомлення, необхідні для адміністрування та використання вашого облікового запису, відповідати на запити щодо обслуговування або для інших немаркетингових цілей.</p>
        <p>Інформація про обліковий запис</p>
        <p>Якщо ви в будь-який момент захочете переглянути чи змінити інформацію у своєму обліковому записі чи припинити дію вашого облікового запису, ви можете:</p>
        <p>Зв’язатися з нами, використовуючи надану контактну інформацію.</p>
        <p>Після вашого запиту на припинення дії вашого облікового запису ми деактивуємо або видалимо ваш обліковий запис і інформацію з наших активних баз даних. Однак ми можемо зберігати певну інформацію в наших файлах, щоб запобігти шахрайству, усунути проблеми, допомогти в будь-яких розслідуваннях, забезпечити дотримання наших юридичних умов і/або дотримання відповідних юридичних вимог.</p>
        <p>Файли cookie та подібні технології: більшість веб-браузерів налаштовано на прийом файлів cookie за замовчуванням. Якщо ви бажаєте, ви зазвичай можете налаштувати свій браузер на видалення та відхилення файлів cookie. Якщо ви вирішите видалити або відхилити файли cookie, це може вплинути на певні функції або служби наших Сервісів.</p>
        <p>Якщо у вас є запитання чи коментарі щодо ваших прав на конфіденційність, ви можете надіслати нам електронний лист на адресу <a href="mailto:admin@pogovorimo.com.ua">admin@pogovorimo.com.ua</a>.</p>
        <p>10. ЕЛЕМЕНТИ КЕРУВАННЯ ФУНКЦІЯМИ НЕ ВІДСТЕЖУВАТИ</p>
        <p>Більшість веб-браузерів і деякі мобільні операційні системи та програми для мобільних пристроїв включають функцію заборони відстеження («DNT» Do not Track). На даному етапі не розроблено єдиного технологічного стандарту для розпізнавання та реалізації сигналів DNT. Таким чином, наразі ми не реагуємо на сигнали браузера DNT або будь-який інший механізм, який автоматично повідомляє про ваш вибір не відстежувати вас в Інтернеті. Якщо буде прийнято стандарт онлайн-відстеження, якого ми повинні дотримуватися в майбутньому, ми повідомимо вас про цю практику в переглянутій версії цього повідомлення про конфіденційність.</p>
        <p>11. ЧИ МАЮТЬ ЖИТЕЛІ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</p>
        <p>Коротко: так, якщо ви є резидентом Каліфорнії, вам надаються певні права щодо доступу до вашої особистої інформації.</p>
        <p>Розділ 1798.83 Цивільного кодексу Каліфорнії, також відомий як закон «Shine The Light», дозволяє нашим користувачам, які є жителями Каліфорнії, запитувати та отримувати від нас раз на рік безкоштовно інформацію про категорії особистої інформації (за наявності), яку ми надаємо третім особам для цілей прямого маркетингу, а також імена й адреси всіх третіх сторін, яким ми надали особисту інформацію безпосередньо за попередній календарний рік. Якщо ви проживаєте в Каліфорнії та бажаєте подати такий запит, надішліть нам свій запит у письмовій формі, використовуючи контактну інформацію, наведену нижче.</p>
        <p>Якщо вам менше 18 років, ви проживаєте в Каліфорнії та маєте обліковий запис у Сервісах, ви маєте право вимагати видалення небажаних даних, які ви публічно опублікуєте в Сервісах. Щоб подати запит на видалення таких даних, зв’яжіться з нами, використовуючи контактну інформацію, наведену нижче, і вкажіть адресу електронної пошти, пов’язану з вашим обліковим записом, і заяву про те, що ви проживаєте в Каліфорнії. Ми подбаємо про те, щоб дані не були публічно відображені в Сервісах, але майте на увазі, що дані не можуть бути повністю видалені з усіх наших систем (наприклад, резервні копії тощо).</p>
        <p>Повідомлення про конфіденційність CCPA</p>
        <p>Кодекс правил Каліфорнії визначає «резидента» як:</p>
        <ul>
          <li>(1) кожну особу, яка перебуває в штаті Каліфорнія не з тимчасовою чи тимчасовою метою, і</li>
          <li>(2) кожну особу, яка проживає в штаті Каліфорнія, яка перебуває за межами штату Каліфорнія з тимчасовою або тимчасовою метою</li>
        </ul>
        <p>Усі інші особи визначаються як «нерезиденти».</p>
        <p>Якщо це визначення «резидента» стосується вас, ми повинні дотримуватися певних прав і зобов’язань щодо вашої особистої інформації.</p>
        <p>Ми  можемо збирати особисту інформацію, коли ви спілкуєтеся з нами особисто, онлайн, або телефоном чи електронною поштою в контексті:</p>
        <ul>
          <li>отримання допомоги через наші канали підтримки клієнтів;</li>
          <li>Участь в опитуваннях або конкурсах клієнтів; та</li>
          <li>сприяння наданню наших Послуг і відповіді на ваші запити.</li>
        </ul>
        <p>Як ми використовуємо та ділимося вашою особистою інформацією?</p>
        <ul>
          <li>Ми використовуємо та надаємо вашу особисту інформацію за допомогою:</li>
          <li>Цільових файлів cookie/Маркетингових файлів cookie</li>
          <li>Маяків/Пікселів/Тегів</li>
        </ul>
        <p>Додаткову інформацію про наші способи збору та обміну даними можна знайти в цієї політики конфіденційності.</p>
        <p>Ви можете зв’язатися з нами електронною поштою за адресою <a href="mailto:admin@pogovorimo.com.ua">admin@pogovorimo.com.ua</a>.</p>
        <p>Якщо ви використовуєте уповноваженого агента, щоб скористатися своїм правом відмовитися, ми можемо відхилити запит, якщо уповноважений агент не надасть доказ того, що він був належним чином уповноважений діяти від вашого імені.</p>
        <p>Ми можемо розкривати вашу особисту інформацію нашим постачальникам послуг відповідно до письмового договору між нами та кожним постачальником послуг. Кожен постачальник послуг є комерційною організацією, яка обробляє інформацію від нашого імені.</p>
        <p>Ми можемо використовувати вашу особисту інформацію для власних бізнес-цілей, наприклад для проведення внутрішніх досліджень для технологічного розвитку та демонстрації. Це не вважається «продажем» вашої особистої інформації.
Протягом останніх дванадцяти (12) місяців ми не продавали жодної особистої інформації третім сторонам з діловою чи комерційною метою.</p>
        <p>Ваші права щодо ваших особистих даних</p>
        <p>Право вимагати видалення даних — Запит на видалення</p>
        <p>Ви можете вимагати видалення вашої особистої інформації. Якщо ви попросите нас видалити вашу особисту інформацію, ми задовольнимо ваш запит і видалимо вашу особисту інформацію, якщо інше не вимагається законом.</p>
        <p>Право на інформацію — Запит на інформацію</p>
        <ul>
          <li>Залежно від обставин ви маєте право знати:</li>
          <li>чи збираємо ми та використовуємо вашу особисту інформацію;</li>
          <li>категорії особистої інформації, яку ми збираємо;</li>
          <li>цілі, для яких використовується зібрана персональна інформація;</li>
          <li>чи продаємо ми вашу особисту інформацію третім сторонам;</li>
          <li>категорії особистої інформації, яку ми продали або розкрили з комерційною метою;</li>
          <li>категорії третіх сторін, яким особиста інформація була продана або розкрита з комерційною метою;</li>
          <li>також ділова або комерційна мета збору або продажу особистої інформації.</li>
        </ul>
        <p>Відповідно до чинного законодавства ми не зобов’язані надавати чи видаляти інформацію про споживача, яка була деідентифікована у відповідь на запит споживача, або повторно ідентифікувати індивідуальні дані для перевірки запиту споживача.</p>
        <p>Право на недискримінацію щодо реалізації прав споживача на конфіденційність</p>
        <p>Ми не будемо дискримінувати вас, якщо ви використовуєте свої права на конфіденційність.</p>
        <p>Процес перевірки</p>
        <p>Отримавши ваш запит, нам потрібно буде підтвердити вашу особу, щоб визначити, що ви та сама особа, про яку ми маємо інформацію в нашій системі. Така перевірка вимагає наш запрос до вас надати інформацію, щоб ми могли порівняти її з інформацією, яку ви нам надали раніше. Наприклад, залежно від типу запиту, який ви подаєте, ми можемо попросити вас надати певну інформацію, щоб ми могли порівняти надану вами інформацію з інформацією, яку ми вже маємо, або ми можемо зв’язатися з вами за допомогою методу зв’язку (наприклад, телефону або електронної пошти), які ви надали нам раніше. Ми також можемо використовувати інші методи перевірки залежно від обставин.</p>
        <p>Ми використовуватимемо особисту інформацію, надану у вашому запиті, лише для підтвердження вашої особи чи повноважень для подання запиту. Наскільки це можливо, ми будемо уникати запросу від вас додаткової інформації з метою перевірки. Проте, якщо ми не можемо підтвердити вашу особу на основі інформації, яку ми вже зберігаємо, ми можемо вимагати від вас надати додаткову інформацію з метою перевірки вашої особи та з метою безпеки чи запобігання шахрайству. Ми видалимо таку додатково надану інформацію, щойно завершимо вашу перевірку.</p>
        <p>Інші права на конфіденційність</p>
        <p>Ви можете заперечувати проти обробки вашої особистої інформації.</p>
        <p>Ви можете вимагати виправлення своїх персональних даних, якщо вони невірні або більше не актуальні, або просити обмежити обробку інформації.</p>
        <p>Ви можете призначити уповноваженого агента для подання запиту відповідно до CCPA від вашого імені. Ми можемо відхилити запит від уповноваженого агента, який не надав доказів того, що він був належним чином уповноважений діяти від вашого імені відповідно до CCPA.</p>
        <p>Ви можете попросити відмовитися від майбутнього продажу вашої особистої інформації третім особам. Отримавши запит на відмову, ми задовольнимо запит якнайшвидше, але не пізніше ніж через п’ятнадцять (15) днів із дати подання запиту.</p>
        <p>Щоб скористатися цими правами, ви можете зв’язатися з нами електронною поштою за адресою <a href="mailto:admin@pogovorimo.com.ua">admin@pogovorimo.com.ua</a>.</p>
        <p>12. ЧИ МИ РОБИМО ОНОВЛЕННЯ ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</p>
        <p>Коротко: так, ми будемо оновлювати цю політику  конфіденційності, якщо це необхідно, щоб дотримуватися відповідних законів.</p>
        <p>Ми можемо час від часу оновлювати цю політику  конфіденційності. Оновлена ​​версія буде позначена оновленою датою «Переглянута», і оновлена ​​версія набуде чинності, щойно стане доступною. Якщо ми внесемо суттєві зміни в цю політику  конфіденційності, ми можемо повідомити вас, розмістивши повідомлення про такі зміни на помітному місці, або безпосередньо надіславши вам повідомлення. Радимо вам частіше переглядати цю політику конфіденційності, щоб бути в курсі того, як ми захищаємо вашу інформацію.</p>
        <p>13. ЯК ВИ МОЖЕТЕ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</p>
        <p>Якщо у вас є запитання чи коментарі щодо цієї політики  конфіденційності, ви можете надіслати нам електронний лист на адресу <a href="mailto:admin@pogovorimo.com.ua">admin@pogovorimo.com.ua</a>.</p>
        <p>14. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЯКІ МИ ЗБИРАЄМО ВІД ВАС?</p>
        <p>Ви маєте право вимагати доступу до особистої інформації, яку ми збираємо від вас, змінити цю інформацію або видалити її. Щоб подати запит на перегляд, оновлення або видалення вашої особистої інформації, ви можете надіслати нам електронний лист на адресу <a href="mailto:admin@pogovorimo.com.ua">admin@pogovorimo.com.ua</a>.</p>
        <div className="s-40"></div>
      </section>
    </>
  )
}

export default Privacy
