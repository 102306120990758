import React from 'react'
import {Link} from 'react-router-dom'


const Footer = () => {
  return (
    <footer>
      <div className="container py-3">
        <div className="row">
          <div className="col-md-4 text-left"><Link to='/' className='title-mini'>Pogovorimo</Link>
          <span className='title-mini'>@2023 - Всі права захищені</span></div>
          <div className="col-md-8 text-right"><Link to='/privacy' className='title-mini'>Політика конфіденційності</Link></div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
