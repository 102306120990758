import React from 'react'

import Left from '../elements/Left'
import Right from '../elements/Right'
import Down from '../elements/Down'

import { MdCheckCircle } from 'react-icons/md'

import agpic from '../../assets/images/agenda.png'

const Agenda = () => {
  return (
    <section className='section agenda'>
      <div className="s-40"></div>
      <Down><h3 className="title-large c-dark my-5 w-100 text-center">Наша філософія</h3></Down>
      <div className="s-40"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="s-20"></div>
            <Right delay={.5}><img src={agpic} alt='Швидкий та ефективний найм IT-спеціалістів' className='h-100 obfc' /></Right>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6">
            <Left delay={0}><p className="title-small">Ми віримо, що люди - це ключ до успіху будь-якого бізнесу, тому ми зосереджуємося на наданні кращих умов для пошуку талановитих IT-спеціалістів. Ми розуміємо, що процес найму може бути непростим та часом важким, тому ми докладаємо максимум зусиль, щоб зробити його більш ефективним та приємним для всіх сторін.
            </p></Left>
            <div className="s-30"></div>
            <Left delay={.25}><p className="title-small">
            Наша філософія ґрунтується на нашому підході до роботи з клієнтами та кандидатами, що полягає в тому, щоб надавати індивідуальний підхід та враховувати потреби кожної сторони. Ми працюємо на результат і завжди намагаємося допомогти нашим клієнтам знайти найкращі рішення.
            </p></Left>
            <div className="s-20"></div>
            <Left delay={0}><h3 className="title-small c-dark">Наші принципи:</h3></Left>
            <div className="s-30"></div>
            <div className="flex">
              <Right delay={.5}><p className='pt-1'><MdCheckCircle size={24} color={'#7ED7DA'} /></p></Right>
              <Left delay={.25}><p className="title-small px-3">Зосередженість на людях: ми віддаємо перевагу підходу "людина до людини" в роботі з нашими клієнтами та кандидатами.</p></Left>
            </div>
            <div className="s-10"></div>
            <div className="flex">
              <Right delay={.75}><p className='pt-1'><MdCheckCircle size={24} color={'#7ED7DA'} /></p></Right>
              <Left delay={.5}><p className="title-small px-3">Передові технології: ми використовуємо передові технології штучного інтелекту та машинного навчання для підвищення ефективності процесу найму.</p></Left>
            </div>
            <div className="s-10"></div>
            <div className="flex">
              <Right delay={1}><p className='pt-1'><MdCheckCircle size={24} color={'#7ED7DA'} /></p></Right>
              <Left delay={.75}><p className="title-small px-3">Індивідуальний підхід: ми працюємо з кожним клієнтом та кандидатом індивідуально, надаючи рішення, які відповідають їх потребам та очікуванням.</p></Left>
            </div>
            <div className="s-100"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Agenda
