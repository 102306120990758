import React from 'react'

const Title = (props) => {
  return (
    <div className='page'>
      <div className="container my-5 py-5">
        <h2 className="title-large my-3 px-4 text-center">{props.text}</h2>
        <h4 className="title-medium my-3 px-5 text-center"><em>{props.desc}</em></h4>
        {props.clidren}
      </div>
    </div>
  )
}
export default Title
