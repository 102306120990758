import { useRef, useEffect } from 'react'
import styled from 'styled-components'

const FadeLeft = styled.div`
  opacity: 0;
  transform: translateX(200px);
  transition: opacity 1s ease-in-out ${props => props.delay || 0}s,
    transform 1s ease-in-out ${props => props.delay || 0}s;

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
`

const Left = ({ delay, children }) => {
  const ref = useRef(null)

  const handleScroll = () => {
    const element = ref.current
    if (!element) return

    const top = element.getBoundingClientRect().top
    const height = window.innerHeight

    if (top + 60 < height) {
      element.classList.add('visible')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <FadeLeft ref={ref} delay={delay}>
      {children}
    </FadeLeft>
  )
}

export default Left
