import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './styles/custom.sass'

import Home from './components/pages/Home'
import Privacy from './components/pages/Privacy'
import NotFound from './components/pages/NotFound'

import Nav from './components/parts/Nav'
import Footer from './components/parts/Footer'

function App() {


  return (
    <BrowserRouter>
      <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
