import React from 'react'
import {Link} from 'react-router-dom'
import Title from '../elements/Title'

const NotFound = () => {
  return (
    <>
      <Title text='Помилка 404' desc='сторінка не знайдена' />
      <section className="my-5 py-md-5 container p-5 m-5 page-content text-center">
        <div className="s-40"></div>
        <p className='w-60 ma c-dark title-small'>
          Перевірте, чи правильно ви ввели адресу, або шукайте потрібну інформацію на <Link to='/'>домашній сторінці</Link>
        </p>
        <div className="s-30"></div>
      </section>
    </>
  )
}
export default NotFound
