import React from 'react'
import {Link} from 'react-router-dom'

import Left from '../elements/Left'
import Down from '../elements/Down'
import Right from '../elements/Right'

import ch1 from '../../assets/images/choise01.png'
import ch2 from '../../assets/images/choise02.png'

const Choise = () => {
  return (
    <section className='section choise flex jcc aic'>
      <div className="container my-5 text-center">
        <Down><h3 className="title-large c-white my-4">Зробіть свій вибір</h3></Down>
        <div className="flex">
          <div className="m-3">
            <Right delay={.25}>
              <div className="w-2 bg-dark text-center m0p0">
                <img src={ch1} alt='Для рекрутерів' className='w-100' />
                <div className="px-5 py-3">
                  <p className="title-medium c-white">Для рекрутерів</p>
                  <div className="s-20"></div>
                  <Link to='#' className="button">Поговоримо?</Link>
                  <div className="s-20"></div>
                </div>
              </div>
            </Right>
          </div>
          <div className="m-3">
            <Left delay={.5}>
              <div className="w-2 bg-dark text-center m0p0">
                <img src={ch2} alt='Для кандидатів' className='w-100' />
                <div className="px-5 py-3">
                  <p className="title-medium c-white">Для кандидатів</p>
                  <div className="s-20"></div>
                  <Link to='#' className="button">Поговоримо?</Link>
                  <div className="s-20"></div>
                </div>
              </div>
            </Left>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Choise
