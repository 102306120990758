import React from 'react'
import {Link} from 'react-router-dom'

import Left from '../elements/Left'
import Right from '../elements/Right'
import Top from '../elements/Top'

import pic from '../../assets/images/cta.png'

const Cta = () => {
  return (
    <section className='section cta'>
      <div className="container-fluid m0p0">
        <div className="row">
          <div className="col-md-6">
            <div className="s-20"></div>
            <Right><img src={pic} alt='Швидкий та ефективний найм IT-спеціалістів' className='h-100 obfc' /></Right>
          </div>
          <div className="col-md-6">
            <div className="flex w-100 h-100 jcc aic px-5">
              <div>
                <Top delay={.25}>
                  <h3 className="title-large c-dark">Швидкий та ефективний найм IT-спеціалістів</h3>
                </Top>
                <div className="s-60"></div>
                <Left delay={.5}><p className="title-small">Ми знаємо, що ви шукаєте талановитих IT-фахівців, які зможуть допомогти вашій компанії зростати та розвиватися. Наш сервіс дозволяє здійснювати швидкий та ефективний найм талановитих IT-спеціалістів з максимальною прозорістю та довірою.</p></Left>
                <div className="s-60"></div>
                <Left delay={.75}><Link to='#' className="button">Поговоримо?</Link></Left>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta
