import React, {useState} from 'react'

import Left from '../elements/Left'
import Top from '../elements/Top'
import Down from '../elements/Down'

const How = () => {

  const [selectedContent, setSelectedContent] = useState("content1")

  const handleButtonClick = (content) => {
    setSelectedContent(content)
  }

  const getContent = () => {
    switch (selectedContent) {
      case "content1":
        return (
          <>
            <Top delay={1.25}><h4 className="c-blue title-small">Інноваційний сервіс, що використовує штучний інтелект, щоб забезпечити брендування, економію часу та юридичний захист для успішного найму талантів</h4></Top>
            <div className="s-50"></div>
            <Top delay={1.5}><p className="c-white title-small">Погововримо - це інноваційний сервіс, який допомагає кандидатам знайти ідеальну IT-вакансію, а рекрутерам - знайти талановитих IT-спеціалістів швидко та ефективно. Наш сервіс використовує штучний інтелект та неперервне удосконалення, щоб забезпечити брендування, економію часу та юридичний захист для успішного найму талантів.</p></Top>
          </>
        )
      case "content2":
        return (
          <>
            <Top delay={1.25}><h4 className="c-blue title-small">Збір даних</h4></Top>
            <div className="s-50"></div>
            <Top delay={1.5}><p className="c-white title-small">Ми збираємо дані про кандидатів з різних джерел, включаючи резюме, профілі в соціальних мережах та з джоббордів</p></Top>
          </>
        )
      case "content3":
        return (
          <>
            <Top delay={1.25}><h4 className="c-blue title-small">Аналіз кандидатів</h4></Top>
            <div className="s-50"></div>
            <Top delay={1.5}><p className="c-white title-small">Наші алгоритми навчені розуміти, які кандидати найбільш підходять для певної вакансії, враховуючи різні параметри, такі як досвід роботи, освіта та навички.</p></Top>
          </>
        )
      case "content4":
        return (
          <>
            <Top delay={1.25}><h4 className="c-blue title-small">Складання рейтингу</h4></Top>
            <div className="s-50"></div>
            <Top delay={1.5}><p className="c-white title-small">Рейтинг кандидатів складається автоматично на основі цих параметрів та інших факторів, враховуючи важливість кожного параметру для конкретної вакансії.</p></Top>
          </>
        )
      case "content5":
        return (
          <>
            <Top delay={1.25}><h4 className="c-blue title-small">Рекомендації та відбір кандидатів</h4></Top>
            <div className="s-50"></div>
            <Top delay={1.5}><p className="c-white title-small">Рекрутери можуть отримувати рекомендації щодо кандидатів та складати свій в початковий список претендентів. Ми забезпечуємо ефективну комунікацію між кандидатами та рекрутерами, щоб забезпечити ефективний процес найму.</p></Top>
          </>
        )
      case "content6":
        return (
          <>
            <Top delay={1.25}><h4 className="c-blue title-small">Постійне вдосконалення</h4></Top>
            <div className="s-50"></div>
            <Top delay={1.5}><p className="c-white title-small">Обратний зв'язок, бігдата та передові технології штучного інтелекту, машинного навчання, аналізу даних та розпізнавання - основа нашого успіху. Наш сервіс неперервно удосконалюється на основі ваших пропозицій та відгуків, щоб забезпечити вам найкращі умови для пошуку талантів у галузі IT.</p>
            <p className="c-white title-small">Інтелектуальний інтерфейс нашої системи забезпечує зручну та ефективну роботу з інформацією про кандидатів, що дозволяє рекрутерам швидко та точно відібрати найбільш підходящих претендентів для вашої компанії. Наші технології дозволяють знаходити кандидатів, які підходять за різними критеріями, включаючи вміння, навички, досвід, освіту та інші фактори.</p>
            <p className="c-white title-small">Будьте впевнені, що використання нашого сервісу допоможе вам ефективно знаходити та наймати найкращих IT-спеціалістів, що забезпечить успішний розвиток вашого бізнесу.</p></Top>
          </>
        )
      default:
        return (
          <>
            <Top delay={1.25}><h4 className="c-blue title-small">Інноваційний сервіс, що використовує штучний інтелект, щоб забезпечити брендування, економію часу та юридичний захист для успішного найму талантів</h4></Top>
            <div className="s-50"></div>
            <Top delay={1.5}><p className="c-white title-small">Погововримо - це інноваційний сервіс, який допомагає кандидатам знайти ідеальну IT-вакансію, а рекрутерам - знайти талановитих IT-спеціалістів швидко та ефективно. Наш сервіс використовує штучний інтелект та неперервне удосконалення, щоб забезпечити брендування, економію часу та юридичний захист для успішного найму талантів.</p></Top>
          </>
        )
    }
  }

  const isActive = (content) => {
    return selectedContent === content ? "r-c active" : "r-c";
  }


  return (
    <section className='section how py-5'>
      <div className="flex jcc aic py-5">
        <div className="container px-4">
          <div className="s-60"></div>
          <Top delay={1}><h3 className="c-white title-large how text-center">Як Поговоримо допомагає користувачам</h3></Top>
          <div className="s-50"></div>
          {getContent()}
          <div className="steps">
            <div className="row">
              <div className="col text-center">
                <Down delay={0}><h3 className="c-white title-mini">Поговоримо</h3></Down>
                <Left delay={1.5}>
                  <button
                    onClick={() => handleButtonClick("content1")}
                    className={isActive("content1")}
                  >1</button>
                </Left>
              </div>
              <div className="col text-center">
                <Down delay={0.25}><h3 className="c-white title-mini">Збір даних</h3></Down>
                <Left delay={1.25}>
                  <button
                    onClick={() => handleButtonClick("content2")}
                    className={isActive("content2")}
                  >2</button>
                </Left>
              </div>
              <div className="col text-center">
                <Down delay={0.5}><h3 className="c-white title-mini">Аналіз кандидатів</h3></Down>
                <Left delay={1}>
                  <button
                    onClick={() => handleButtonClick("content3")}
                    className={isActive("content3")}
                  >3</button>
                </Left>
              </div>
              <div className="col text-center">
                <Down delay={0.75}><h3 className="c-white title-mini">Рейтинг</h3></Down>
                <Left delay={0.75}>
                  <button
                    onClick={() => handleButtonClick("content4")}
                    className={isActive("content4")}
                  >4</button>
                </Left>
              </div>
              <div className="col text-center">
                <Down delay={1}><h3 className="c-white title-mini">Рекомендації</h3></Down>
                <Left delay={0.5}>
                  <button
                    onClick={() => handleButtonClick("content5")}
                    className={isActive("content5")}
                  >5</button>
                </Left>
              </div>
              <div className="col text-center">
                <Down delay={1.25}><h3 className="c-white title-mini">Вдосконалення</h3></Down>
                <Left delay={0.25}>
                  <button
                    onClick={() => handleButtonClick("content6")}
                    className={isActive("content6")}
                  >6</button>
                </Left>
              </div>
              <div className="s-100"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default How
